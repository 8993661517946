/* mobile media queries */
/**
  based on break points using bootstrap grid


**/



@media screen and ( max-width: 1090px ) {
  .navbar-nav .dropdown-menu{
    position: absolute;
  }
  .heroHeading{
    padding-top: 11rem;
    width:100%;
  }

}


@media screen and ( max-width: 810px ) {
  .navbar-nav .dropdown-menu{
    position: absolute;
  }
  .heroHeading{
    padding-top: 11rem;
    width:100%;
  }

  .subscribe-form{
    padding: 0px 2%;
  }

  .nav-item:not(:last-child){
    border-bottom: 1px solid #fff;
  }

  .nav-item{
    padding: 7px 0px;
  }

  .navbar-dark .navbar-toggler{
    border-color: #fff;
  }

  .navbar-dark .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .quick-takes-banner{
    height:300px;
  }

  .quick-takes-banner h1{
    padding: 75px 0 25px 0;
  }

  .banner-video{
    top:0;
  }

  .quick-takes-area{
    height:auto;
  }


}

/* iPad */
@media screen and ( max-width: 768px ) {
  .navbar-nav .dropdown-menu{
    position: absolute;
  }
  .heroHeading{
    padding-top: 11rem;
    width:100%;
  }

  .related-posts{
    display: block;
  }

  .subscribe-form{
    padding: 0px 2%;
  }

  .counters h3{
    min-height:0px;
    padding:25px 0px;
  }

  .banner-form-area{
    width:100%;
  }

  .banner{
    padding: 25px;
  }

  .blogMeta{
    text-align:center;
  }



}

/* iPhone */
@media ( max-width: 450px ) {
  .btn{
    width:100%;
  }

  .navbar-nav .dropdown-menu{
    position: absolute;
  }

  .heroHeading{
    padding-top: 7rem;
    width:100%;
  }

  .hero{
    min-width:100%;
    height:500px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    overflow:hidden;
  }

  .overlay{
    position: absolute;
    height:100%;
    width: 100%;
    transition: .75s ease;
    background-color: rgba(243,243,243,.25);
    overflow: hidden;
  }

  .ov{
    background:rgba(68,68,68,1);
  }

  .overlay h3{
    color:#fff;
    background-color: rgba(0,0,0,.5);
  }

  .counters h3{
    min-height:0px;
    padding:25px 0px;
  }

  .slide-up-effect{ display:none; }
  .fade-effect-block{  }

  footer ul{
    text-align: center;
  }

}

@media ( max-width: 400px ) {
  .navbar-brand{
    font-size:18px;
    margin-right: 0;
  }
}
